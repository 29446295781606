/*--------------------------------------------------------------
MAGNET  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Footer
- Sections
- Nav
- Slides
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#philo, #savoir{
	padding: 0;
	text-align: center;
	.main{
		display: grid;
		height: 100vh;
		p{
			margin: auto;
			max-width: 580px;
			@include laptopL{
				font-size: 13px;
			}
		}
	}
	&.scrollable{
		padding-bottom: $footerH;
		.main{
			grid-auto-rows: auto;
			height: auto;
		}
	}
}



/*--------------------------------------------------------------
-	Footer
--------------------------------------------------------------*/
#philo, #savoir{
	footer{
		position: fixed;
		transition: .3s;
		z-index: 2;
		&.off{
			transform: translateY(100%);
		}
	}
	&.scrollable{
		footer{
			position: absolute;
			transform: none;
		}
	}
}



/*--------------------------------------------------------------
-	Sections
--------------------------------------------------------------*/
.magnet{
	grid-row: 1;
	grid-column: 1;
	overflow: hidden;
	z-index: 1;
	&.off{
		z-index: 0;
	}
	&.enter{
		z-index: 2;
	}
	.scrollable &{
		grid-row: auto;
	}
}



/*--------------------------------------------------------------
-	Nav
--------------------------------------------------------------*/
.magnet_nav{
	grid-row: 1;
	grid-column: 1;
	align-self: center;
	justify-self: end;
	margin-right: 20px;
	z-index: 3;
	@include laptopL{
		margin-right: 0;
	}
	.scrollable &{
		display: none;
	}
	button{
		display: block;
		position: relative;
		width: 20px;
		height: 20px;
		border: 10px solid transparent;
		border-radius: 50%;
		background: $gris1 content-box;
		box-sizing: content-box;
		&:after{
			@include pseudo;
			@include abs;
			background: #fff;
			border-radius: 50%;
			opacity: 0;
			transition: .3s;
			transform: scale(0);
		}
		&:hover{
			&:after{
				opacity: 1;
				transform: scale(.3);
			}
		}
		&.on{
			&:after{
				opacity: 1;
				transform: scale(.6);
				background-color: $vert1;
			}
		}
		&:last-child {
			display: none;
		}
	}
}



/*--------------------------------------------------------------
-	Slides
--------------------------------------------------------------*/
.slideshow{
	display: grid;
	div{
		grid-row: 1;
		grid-column: 1;
		opacity: 0;
		transform: translateY(20px);
	}
	img{
		opacity: 0;
	}
}
@for $i from 1 through 4 {
	&.slide#{$i}{
		img:nth-child(#{$i}){
			opacity: 1;
		}
		div:nth-child(#{$i}){
			opacity: 1;
			transform: none;
		}
	}
}
