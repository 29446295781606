/*--------------------------------------------------------------
PHILO  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Taste
- Seasons
- Tiles
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Taste
--------------------------------------------------------------*/
#taste{
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas: "i t";
	&:before{
		content: '';
		grid-area: t;
		background-color: $gris3;
		transition: .8s $quint;
		transform-origin: 0 0;
	}
	@include tablet{
		grid-template-areas: "i" "t";
		grid-template-columns: 1fr;
		grid-template-rows: 350px auto;
	}
	figure{
		grid-area: i;
		@include cover(1000, 1080);
		transition: .7s .2s $quint;
	}
	img{
		transition: .7s .2s $quint;
	}
	div{
		grid-area: t;
		align-self: center;
		padding: $gap*2 0;
		z-index: 1;
		transition: .4s .9s, opacity .4s 1s;
		@include mobileL{
			padding: $gap*2 0;
		}
	}
	h1{
		@include title;
		@include trait;
	}

	// Off
	&.off{
		&:before{
			transform: scaleX(0);
			transition: 0s;
		}
		figure{
			transform: translateX(-100%);
			transition: 0s;
		}
		img{
			transform: translateX(50%);
			transition: 0s;
		}
		div{
			opacity: 0;
			transform: translateY(40px);
			transition: 0s;
		}
	}
}



/*--------------------------------------------------------------
-	Seasons
--------------------------------------------------------------*/
#seasons-philo{
	display: grid;
	color: #fff;
	grid-template-columns: 50% 25% 25%;
	grid-template-areas: "t col .";
	align-items: center;
	@include laptopL{
		grid-template-columns: 50% 50%;
		grid-template-areas: "t col";
	}
	@include tablet{
		grid-template-columns: 1fr;
		grid-auto-rows: auto;
		grid-template-areas:
			"t"
			"col";
	}

	// Col
	&:before{
		content: '';
		grid-area: col;
		background: $vert1;
		align-self: stretch;
		transition: .8s .8s $quint;
		transform-origin: 0 0;
		z-index: 1;
		@include tablet{
			display: none;
		}
	}

	// Bg
	figure{
		@include cover;
		grid-row: 1;
		grid-column: 1/4;
		align-self: stretch;
		z-index: 0;
		transition: 1s $quint;
		transform-origin: 0 100%;
		img{
			transition: 1s $quint;
		}
	}

	// Title
	h1{
		grid-area: t;
		padding: $gap*2 $gap;
		@include title;
		z-index: 1;
		transition: .3s 1s;
	}

	// Col
	div{
		grid-area: col;
		padding: $gap*2 0;
		background: $vert1;
		z-index: 2;
		transition: .4s 1.3s, opacity .4s 1.4s;
		@include tablet{
			margin-top: -3px;
		}
	}
	h2{
		@include subtitle;
		@include trait;
	}

	// Btn
	.seasons_btn{
		position: relative;
		display: inline-block;
		margin-top: $gap*3;
		&:before{
			content: '';
			@include abs;
			border-radius: 50%;
			background: rgba(#000, .2);
			transition: .2s;
			z-index: -1;
			transform: scale(.7);
		}
		&:hover{
			&:before{
				transform: scale(1.15);
			}
		}
		svg{
			display: block;
			width: 150px;
			height: 150px;
		}
	}

	// Off
	&.off{
		&:before{
			transform: scaleX(0);
			transition: 0s;
		}
		figure{
			transform: translateY(100%);
			transition: 0s;
			img{
				transform: translateY(-50%);
				transition: 0s;
			}
		}
		h1, div{
			opacity: 0;
			transform: translateY(40px);
			transition: 0s;
		}
	}
}



/*--------------------------------------------------------------
-	Tiles
--------------------------------------------------------------*/
#tiles{
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-auto-rows: 1fr;
	grid-template-areas:
		"t1 t2"
		"t3 t4";
	@include laptop{
		grid-auto-rows: auto;
	}
	@include mobileL{
		grid-template-columns: 1fr;
		grid-auto-rows: min-content;
		grid-template-areas:
			"t1"
			"t2"
			"t3"
			"t4";
	}
	&:before{
		content: '';
		grid-column: 1/4;
		grid-row: 1/3;
		background: #fff;
		transition: 1s $quint;
		transform-origin: 0 0;
	}

	// Sections
	section{
		display: grid;
		grid-template-columns: 50% 50%;
		grid-template-areas: "t i";
		align-items: center;
		@for $i from 1 through 4 {
			&:nth-child(#{$i}){
				grid-area: (t + $i);
			}
		}
	}
	.inverse{
		grid-template-areas: "i t";
		&:before{
			content: '';
			grid-column: 2;
			grid-row: 1;
			align-self: stretch;
			background: $vert4;
			transition: 1s $quint;
			transform-origin: 0 0;
		}
	}
	section, .inverse{
		@include laptop{
			grid-template-columns: 1fr;
			grid-template-rows: 1fr 1fr;
			grid-template-areas: "t" "i";
			&:nth-child(even){
				grid-template-areas: "i" "t";
			}
		}
		@include mobileL{
			&:nth-child(even){
				grid-template-areas: "t" "i";
			}
		}
	}

	// Imgs
	figure{
		grid-area: i;
		align-self: stretch;
		@include cover(720, 540);
		img{
			transition: 1s $quint;
			transform-origin: 0 0;
			position: absolute;
		}
	}

	// Text
	div{
		transition: .4s;
		z-index: 1;
		@include laptop{
			padding: $gap*2 0;
		}
	}
	p{
		line-height: 1.3;
	}
	section>div{
		grid-area: t;
	}

	// Transitions
	@for $i from 1 through 4 {
		section:nth-child(#{$i}){
			&:before{
				transition-delay: $i/5 + 0s;
			}
			img{
				transition-delay: $i/10 + .2s;
			}
			div{
				transition-delay: $i/5 + .4s;
			}
		}
	}

	// Stats
	.stats{
		h1{
			font-size: 2.85em;
			span{
				display: block;
				margin-top: -.35em;
				font-size: 2.5em;
				@include dalafloda;
			}
		}
		p{
			margin-top: 1em;
			font-style: italic;
			font-size: 1.4em;
		}
		@include tablet{
			font-size: .75em;
		}
	}

	// Txt
	.txt{
		h1{
			@include subtitle;
			margin-bottom: 10px;
		}
	}
	.btn_prod{
		margin-top: $gap;
		width: 80px;
	}

	// Off
	&.off{
		&:before{
			transform: scaleX(0);
			transition: 0s;
		}
		.inverse{
			&:before{
				transform: scaleX(0);
				transition: 0s;
			}
		}
		figure{
			img{
				transform: translateX(-101%);
				transition: 0s;
			}
		}
		div{
			opacity: 0;
			transform: translateY(80px);
			transition: 0s !important;
		}
	}
}
