html, body, div, span, applet, button, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: none;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after, q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
a, button{
	color: inherit;
	cursor: pointer;
	text-decoration: none;
}
button{
	border: none;
	background: none;
	text-transform: inherit;
}
button, input, textarea{
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	outline: none;
}
input{
	&[type=submit], &[type=button]{
		cursor: pointer;
	}
}
video::-webkit-media-controls-panel {
	display: none!important;
	-webkit-appearance: none;
}
video::--webkit-media-controls-play-button {
	display: none!important;
	-webkit-appearance: none;
}
video::-webkit-media-controls-start-playback-button {
	display: none!important;
	-webkit-appearance: none;
}
