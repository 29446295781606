/*--------------------------------------------------------------
PRODUCTEURS > MAP  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Maps
- World
- France
- Zoom
- Tooltip world
- Tooltip france
- Producteur
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#map{
	display: flex;
	@include abs;
	background: $vert2 url(/img/producteurs/pattern.svg) left / 144px;
	overflow: hidden;
	user-select: none;
}



/*--------------------------------------------------------------
-	Maps
--------------------------------------------------------------*/
.svg_map{
	@include abs(1);
	width: 100%;
	height: 100%;
	transition: {
		property: opacity, visibility, transform;
		duration: .5s;
	}
	&.off{
		opacity: 0;
		visibility: hidden;
	}
	path{
		fill: $gris2;
		stroke: $vert1;
		stroke-width: .25px;
		&:not(.off){
			cursor: pointer;
			transition: fill .2s;
		}
	}
	.off{
		fill: #fff;
	}
}



/*--------------------------------------------------------------
-	World
--------------------------------------------------------------*/
#world_map{
	&.off{
		// transform: scale(7);
		// transform-origin: 735px 380px;
	}
	path:not(.off){
		&:hover{
			fill: desaturate(lighten($orange, 20), 20);
		}
		&.on{
			fill: $orange;
		}
	}
}
#cFR{
	fill: $vert1;
	cursor: pointer;
	transition: fill .2s;
	&:hover{
		fill: $vert3;
	}
}



/*--------------------------------------------------------------
-	France
--------------------------------------------------------------*/
#france_map{
	&.off{
		transform: scale(.3);
		transform-origin: 750px 490px;
	}
	&.zoomed{
		path:not(.active){
			opacity: 0;
			visibility: hidden;
			transition-delay: 0s;
		}
	}
	path{
		transition: fill .3s, opacity .3s, visibility .3s, transform .5s .3s;
		&:not(.off){
			&:hover{
				fill: $vert1;
			}
		}
		&.active{
			fill: $vert1;
			cursor: default;
			z-index: 1;
			transition-delay: .2s;
		}
		&.leaving{

		}
	}
}



/*--------------------------------------------------------------
-	Zoom
--------------------------------------------------------------*/
#zoom{
	position: fixed;
	right: 60px;
	bottom: 300px;
	width: 30px;
	height: 60px;
	background: $gris3;
	border-radius: 3px;
	box-shadow: 0 0 10px rgba(#000, .1);
	overflow: hidden;
	transition: {
		property: opacity, visibility;
		duration: .2s;
	};
	&.hidden{
		opacity: 0;
		visibility: hidden;
	}
	&::after{
		content: '';
		display: block;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 3px;
		right: 3px;
		margin: auto;
		height: 1px;
		background: $gris2;
		transition: .2s;
	}
	&:hover{
		&::after{
			opacity: 0;
		}
	}
	button{
		display: block;
		position: relative;
		width: 100%;
		height: 50%;
		color: darken($gris1, 20);
		z-index: 1;
		transition: .2s;
		&::before, &::after{
			content: '';
			display: block;
			@include abs(1);
			width: 10px;
			height: 2px;
			background: currentColor;
		}
		&::after{
			transform: rotate(90deg);
		}
		&:hover{
			color: darken($gris1, 30);
			background: $gris2;
		}
	}
	.minus{
		&::after{
			display: none;
		}
	}
	@mixin disabled{
		color: $gris1;
		background: lighten($gris2, 5);
		cursor: default;
	}
	&.min{
		&::after{
			opacity: 0;
		}
		.minus{
			@include disabled;
		}
	}
	&.max{
		&::after{
			opacity: 0;
		}
		.plus{
			@include disabled;
		}
	}
}



/*--------------------------------------------------------------
-	Tooltip world
--------------------------------------------------------------*/
$tooltipColor: rgba(#e2f3ea, .8);
#tooltip_world{
	position: absolute;
	padding: 15px;
	width: 280px;
	background: $tooltipColor;
	border-radius: 3px;
	z-index: 10;
	transition: {
		property: opacity, visibility, transform;
		duration: .2s;
	}
	&:not(.on){
		opacity: 0;
		visibility: hidden;
		transform: translateY(-10px);
	}
	&::after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -15px;
		width: 0;
		height: 0;
		margin: auto;
		border: 10px solid transparent;
		border-top: 15px solid $tooltipColor;
		border-bottom: none;
	}
	&.right{
		&::after{
			left: auto;
			right: 20px;
			margin: 0;
		}
	}
	&.left{
		&::after{
			right: auto;
			left: 20px;
			margin: 0;
		}
	}
	img{
		float: left;
		margin-right: 15px;
	}
	h2{
		padding: 5px 0 10px;
		font-size: 16px;
		@include dalafloda;
	}
	p{
		margin-bottom: 5px;
		padding: 0;
		font-size: 10px;
		line-height: 1.3;
	}
	a{
		font-size: 10px;
		font-weight: bold;
		font-style: italic;
		text-decoration: underline;
	}
}



/*--------------------------------------------------------------
-	Tooltip france
--------------------------------------------------------------*/
#tooltip_france{
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	padding: 10px 15px;
	@include sans;
	text-transform: uppercase;
	border-radius: 3px;
	background: $orange;
	pointer-events: none;
	transition: {
		property: opacity, visibility;
		duration: .2s;
	}
	&:not(.on){
		opacity: 0;
		visibility: hidden;
	}
	&::after{
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: -6px;
		width: 0;
		height: 0;
		margin: auto;
		border: 3px solid transparent;
		border-top: 7px solid $orange;
		border-bottom: none;
	}
}



/*--------------------------------------------------------------
-	Producteur
--------------------------------------------------------------*/
#map{
	.prod{
		display: block;
		position: absolute;
		width: 18px;
		height: 18px;
		margin: -9px;
		color: $vert1;
		border-radius: 50%;
		cursor: pointer;
		transition: {
			property: opacity, transform;
			duration: .2s;
		};
		animation: prodIn .2s .6s backwards;
		&.hidden{
			opacity: 0;
			transform: scale(2);
		}
		&:nth-of-type(3n+1){
			background: $orange;
		}
		&:nth-of-type(3n+2){
			background: $bleu;
		}
		&:nth-of-type(3n+3){
			background: $jaune;
		}
		div{
			display: inline-block;
			position: absolute;
			left: 50%;
			bottom: 35px;
			padding: 10px 15px;
			white-space: nowrap;
			border-radius: 3px;
			background: #fff;
			z-index: 1;
			transition: {
				property: opacity, visibility, transform;
				duration: .2s;
			};
			transform: translateX(-50%);
			&::before{
				content: '';
				@include abs(0, bottom);
				bottom: -35px;
				width: 30px;
				margin: auto;
			}
			&::after{
				content: '';
				position: absolute;
				left: 0;
				right: 0;
				bottom: -9px;
				width: 0;
				height: 0;
				margin: auto;
				border: 4px solid transparent;
				border-top: 9px solid #fff;
				border-bottom: none;
			}
		}
		&:not(:hover){
			div{
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
				transform: translate(-50%, 20px);
				&::before{
					display: none;
				}
			}
		}
		h1{
			margin-bottom: 2px;
			padding: 0;
			@include dalafloda;
			font-size: 1.4em;
		}
		p{
			padding: 0;
			@include sans;
			font-size: 11px;
		}
	}
}
@keyframes prodIn{
	0%{
		opacity: 0;
		transform: scale(2);
	}
}
