/*--------------------------------------------------------------
ROUE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Branches
- Points
- Mois
- Titre
- Imgs
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
.roue_wrapper{
	display: flex;
	position: relative;
	align-items: center;
	top: -($headerH + $gap*2);
	padding: ($headerH + $gap*2) ($headerH/2 + $gap) 0;
	height: 100vh;
	width: 100vh;
	margin: auto;
	margin-bottom: -($headerH + $gap*2);
	box-sizing: border-box;
	@include tablet{
		height: 90vw;
		width: 90vw;
		top: 0;
		padding: 0;
	}
}
#roue{
	position: relative;
	height: 65%;
	width: 65%;
	margin: auto;
	border: 2px solid $vert1;
	border-radius: 50%;
	backface-visibility: hidden;
	box-sizing: border-box;
}



/*--------------------------------------------------------------
	Branches
--------------------------------------------------------------*/
#roue label{
	display: block;
	position: absolute;
	top: 0;
	left: 50%;
	margin-left: -40px;
	width: 80px;
	height: 25%;
	transform-origin: 50% 200%;
	cursor: pointer;
	z-index: 3;
	@for $i from 1 through 12{
		&:nth-child(#{$i}){
			transform: rotate(#{$i*30-30}deg);
		}
	}
	@include mobileL{
		width: 40px;
		margin-left: -20px;
	}
	&:before{
		@include pseudo;
		position: absolute;
		top: -80px;
		left: -40px;
		right: -40px;
		bottom: 0;
		@include mobileL{
			top: -50px;
			left: -10px;
			right: -10px;
		}
	}
}



/*--------------------------------------------------------------
-	Points
--------------------------------------------------------------*/
#roue{
	span{
		display: block;
		position: absolute;
		margin-left: -8px;
		top: -8px;
		left: 50%;
		width: 14px;
		height: 14px;
		background: $vert1;
		border-radius: 50%;
		transition: .2s;
		@include mobileL{
			margin-left: -5px;
			top: -5px;
			width: 8px;
			height: 8px;
		}
		&:before{
			@include pseudo;
			@include abs;
			background-color: #fff;
			border-radius: 50%;
			z-index: 1;
			transform: scale(0);
			transition: .2s;
		}
	}
	label:hover span{
		transform: scale(1.5);
	}
	input{
		display: none;
	}
	input:checked + span{
		transform: scale(2.5);
		&:before{
			transform: scale(.4);
		}
	}
}



/*--------------------------------------------------------------
-	Mois
--------------------------------------------------------------*/
#roue{
	p{
		position: relative;
		padding: 0;
		left: -1px;
		top: -25px;
		@include sans;
		line-height: 1;
		letter-spacing: 2px;
		text-transform: uppercase;
		font-size: 12px;
		user-select: none;
		transform: rotate(-90deg);
		text-align: right;
		@include mobileL{
			font-size: 8px;
		}
	}
	label:nth-child(n+7) p{
		text-align: left;
		transform: rotate(90deg);
	}
}



/*--------------------------------------------------------------
-	Titre
--------------------------------------------------------------*/
#roue div{
	position: absolute;
	top: 50%;
	left: 0;
	right: 0;
	margin: auto;
	width: 60%;
	@include dalafloda;
	font-size: 3.7em;
	color: #fff;
	text-align: center;
	z-index: 2;
	transform: translateY(-50%);
	@include laptopL{
		font-size: 2.5em;
	}
	@include mobileL{
		font-size: 1.6em;
	}
}



/*--------------------------------------------------------------
-	Imgs
--------------------------------------------------------------*/
#roue{
	figure{
		position: absolute;
		top: 5%;
		left: 5%;
		right: 5%;
		bottom: 5%;
		overflow: hidden;
		border-radius: 50%;
		z-index: 1;
		background: #000;
	}
	img{
		position: absolute;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: .6s;
		backface-visibility: hidden;
		&.on{
			opacity: 1;
		}
	}
}
