/*--------------------------------------------------------------
POPIN  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Cache
- Wrapper
--------------------------------------------------------------*/

/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
#popin {
  @include fix;
  text-align: center;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s;
  &.on {
    opacity: 1;
    visibility: visible;
  }
}

/*--------------------------------------------------------------
	Cache
--------------------------------------------------------------*/
#popin .cache {
  @include abs;
  background: rgba(#000, 0.5);
}

/*--------------------------------------------------------------
	Wrapper
--------------------------------------------------------------*/
#popin {
  .wrapper {
    @include abs(1);
    width: 440px;
    height: 300px;
    line-height: 2.7;
    text-align: center;
    border: 5px solid #fff;
    background: $vert2;
    @include mobileL {
      width: 300px;
      height: 300px;
    }
  }
  svg {
    width: 50px;
    height: 39px;
    fill: $vert1;
  }
  g {
    fill: none;
    stroke: $vert1;
    stroke-width: 2px;
  }
  h2 {
    margin: 30px 0 20px;
    text-transform: uppercase;
    font-size: 30px;
    color: #fff;
  }
  strong {
    font-size: 2em;
  }
  p {
    margin-top: 40px;
    font-size: 20px;
    @include mobileL {
      margin-top: 50px;
      font-size: 15px;
    }
  }
  a {
    text-decoration: underline;
  }
  .close {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    &:before,
    &:after {
      @include pseudo;
      @include abs(1);
      width: 4px;
      height: 18px;
      background: #fff;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }
}
