/*--------------------------------------------------------------
SAISONS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Grids
- Feuille de marché
- Mois
- Placements
- Vedette
- Fin
- Up
- En savoir plus
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Grids
--------------------------------------------------------------*/
@mixin seasons_grids {
	grid-template-columns: repeat(2, 320px);
	grid-column-gap: 120px;
	justify-content: center;
	@include laptop{
		grid-template-columns: repeat(2, 300px);
		grid-column-gap: $gap*2;
	}
	@include mobileL{
		grid-template-columns: 1fr;
	}
}



/*--------------------------------------------------------------
-	Feuille de marché
--------------------------------------------------------------*/
.month_sheet{
	position: relative;
	font-style: italic;
	text-align: center;
	background: $gris3;
	z-index: 1;
	@include mobileL{
		margin-bottom: $gap;
	}
	p{
		margin: auto;
		max-width: 450px;
		line-height: $gap*2;
		@include mobileL{
			padding: $gap/2;
			font-size: .9em;
			line-height: 1.5;
		}
	}
	a{
		@include dalafloda;
		font-size: 1.3em;
		font-style: normal;
	}
}



/*--------------------------------------------------------------
-	Mois
--------------------------------------------------------------*/
#saisons .mois{
	display: none;
	position: relative;
	margin: 200px auto 80px;
	padding: 0 $gap 50px;
	@include seasons_grids;
	grid-template-areas:
		"t t"
		"f w"
		"v w"
		"e w";
	&.on{
		display: grid;
	}
	@include mobileL{
		margin-top: 250px;
		grid-template-areas:
			"t"
			"f"
			"v"
			"e"
			"w";
	}
	h1{
		grid-area: t;
		margin-bottom: 80px;
		@include subtitle;
		text-align: center;
		@include mobileL{
			margin-bottom: 40px;
			font-size: 2.5em;
		}
	}
	h2{
		margin-bottom: $gap;
		@include sans;
		font-weight: 700;
		text-align: center;
		text-transform: uppercase;
	}
	h3{
		margin-bottom: 5px;
		padding: 0;
		@include sans;
		font-size: 12px;
		text-transform: uppercase;
	}
	p{
		margin-bottom: $gap;
		padding: 0;
		font-size: 11px;
		&:last-child{
			margin: 0;
		}
	}
}



/*--------------------------------------------------------------
-	Placements
--------------------------------------------------------------*/
#saisons{
	.france{
		grid-area: f;
	}
	.monde{
		grid-area: v;
	}
	.vedette{
		grid-area: w;
	}
	.fin{
		grid-area: e;
	}
}



/*--------------------------------------------------------------
-	Vedette
--------------------------------------------------------------*/
.vedette{
	position: relative;
	padding: $gap*2 $gap;
	text-align: center;
	border: 4px solid $vert2;
	// margin: 50px 0;
	&:before{
		@include pseudo;
		position: absolute;
		top: 6px;
		left: 6px;
		right: 6px;
		bottom: 6px;
		border: 1px solid $vert2;
		z-index: -1;
	}
}
/*--------------------------------------------------------------
-	Monde
--------------------------------------------------------------*/
.monde{
	margin: 50px 0;
}



/*--------------------------------------------------------------
-	Fin
--------------------------------------------------------------*/
#saisons .fin{
	text-align: center;
}



/*--------------------------------------------------------------
-	Up
--------------------------------------------------------------*/
.mois .up{
	display: block;
	position: absolute;
	margin: auto;
	left: 0;
	bottom: 0;
	right: 0;
	width: 0;
	height: 0;
	border: 9px solid transparent;
	border-bottom: 12px solid $vert1;
}



/*--------------------------------------------------------------
-	En savoir plus
--------------------------------------------------------------*/
#saisons .see_more{
	display: grid;
	padding: 80px $gap 40px;
	@include seasons_grids;
	align-items: center;
	text-align: center;
	background: $vert4;
	@include mobileL{
		padding: 40px $gap 20px;
	}
	p{
		padding: 0;
		line-height: 1.2;
		text-align: right;
		@include subtitle;
		@include mobileL{
			margin-bottom: 20px;
			text-align: center;
		}
	}
	.btn_prod{
		width: 120px;
		height: 120px;
		@include mobileL{
			margin: auto;
		}
	}
}
