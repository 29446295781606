/*--------------------------------------------------------------
GLOBAL  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Body
- Border
- Main
- Btn Prod
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Body
--------------------------------------------------------------*/
body{
	padding-bottom: $footerH;
	position: relative;
	overflow-y: scroll;
	&#producteurs{
		padding-bottom: 0;
	}
	@include tablet{
		padding-bottom: $footerH/1.5;
	}
	@include mobileL{
		padding-bottom: $footerH/2 + 30px;
	}
}



/*--------------------------------------------------------------
	Border
--------------------------------------------------------------*/
$pageBorder: 8px;
.border{
	position: fixed;
	border: $pageBorder solid $vert1;
	z-index: 11;
	transition: .2s;
	$a: (top, left, right), (top, left, bottom), (left, bottom, right), (bottom, right, top);
	@for $i from 1 through 4 {
		$b: nth($a, $i);
		&:nth-child(#{$i}) {
			#{nth($b, 1)}: 0;
			#{nth($b, 2)}: 0;
			#{nth($b, 3)}: 0;
		}
	}
	@include mobileL{
		border-width: $pageBorder/2;
	}
}



/*--------------------------------------------------------------
	Main
--------------------------------------------------------------*/
.main{
	position: relative;
	min-height: 100vh;
	margin-bottom: $pageBorder*-2;
	padding: $headerH $pageBorder*2 $pageBorder*2 $pageBorder*2;
	box-sizing: border-box;
	@include laptop{
		padding-top: $headerH/1.3;
	}
	@include mobileL{
		padding: $headerH/2 $pageBorder 0;
	}
}



/*--------------------------------------------------------------
-	Btn Prod
--------------------------------------------------------------*/
.btn_prod{
	display: inline-block;
	position: relative;
	border-radius: 50%;
	box-shadow: 0 2px 15px $vert2;
	z-index: 0;
	&:before{
		@include pseudo;
		@include abs;
		z-index: -1;
		border-radius: 50%;
		background: rgba($gris3, .5);
		transition: .2s $quint;
	}
	img{
		display: block;
	}
	&:hover{
		&:before{
			transform: scale(1.3);
		}
	}
}
