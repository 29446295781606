/*--------------------------------------------------------------
PRESSE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Articles
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Articles
--------------------------------------------------------------*/
#presse{
	background: $gris3;
	article{
		display: grid;
		grid-template-columns: 450px 400px;
		grid-auto-rows: min-content;
		grid-column-gap: 50px;
		grid-template-areas: "i t";
		align-items: center;
		margin: 100px auto;
		padding: 0 $gap*2;
		max-width: 900px;
		@include laptop{
			grid-template-columns: 1fr 1fr;
			grid-gap: $gap*2;
			align-items: start;
		}
		@include mobileL{
			grid-template-columns: 1fr;
			grid-template-areas: "i" "t";
			grid-gap: $gap;
			padding: $gap;
			margin: 0 0 $gap*2;
		}
		figure{
			grid-area: i;
		}
		img{
			width: 100%;
			transition: .2s;
			&:hover{
				box-shadow: 0 5px 15px $gris1;
			}
		}
		div{
			grid-area: t;
		}
		h1{
			margin: $gap/2 0 $gap;
			padding: 0;
			@include subtitle;
		}
		p{
			margin-bottom: $gap*3;
			grid-area: c;
			padding: 0;
			@include mobileL{
				margin-bottom: $gap;
			}
		}
		.date{
			margin: 0;
			grid-area: d;
			@include sans;
			font-size: 12px;
			color: $gris1;
		}
		.link{
			grid-area: l;
			@include sans;
			font-weight: bold;
			text-decoration: underline;
		}
	}
}
