/*--------------------------------------------------------------
MIXINS  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Beziers
- Font-face
- Screen sizes
- Scrollbar
- Full
- Pseudo
- Typo
- Cover
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Beziers
--------------------------------------------------------------*/
$quint: cubic-bezier(.7, 0, .3, 1);
$boing: cubic-bezier(.7, 0, .6, 1.5);



/*--------------------------------------------------------------
	Font-face
--------------------------------------------------------------*/
$weights: ('thin': 100, 'light': 300, 'regular': 400, 'medium': 500, 'semibold': 600, 'bold': 700, 'black': 900);
@mixin fontFace($name, $weight, $italic: false){
	$extW: if($weight == regular and $italic, '', '-'+$weight);
	$extI: if($italic, '-italic', '');
	$url: '/fonts/'+$name+$extW+$extI+'.woff';
	@font-face{
		font-family: $name;
		src: url($url+'2') format('woff2'), url($url) format('woff');
		@if($weight != 'regular'){
			font-weight: map-get($weights, $weight);
		}
		@if($italic){
			font-style: italic;
		}
		font-display: swap;
	}
}



/*--------------------------------------------------------------
	Screen sizes
--------------------------------------------------------------*/
@mixin XXL{@media(min-width: 1920px){@content;}}
@mixin laptopL{@media(max-width: 1440px){@content;}}
@mixin laptop{@media(max-width: 1024px){@content;}}
@mixin tablet{@media(max-width: 768px){@content;}}
@mixin mobileL{@media(max-width: 425px){@content;}}
@mixin mobileM{@media(max-width: 375px){@content;}}
@mixin mobileS{@media(max-width: 320px){@content;}}



/*--------------------------------------------------------------
	Scrollbar
--------------------------------------------------------------*/
@mixin scrollbar($size, $fg, $bg){
	::-webkit-scrollbar{
		width: $size;
		height: $size;
	}
	::-webkit-scrollbar-track{
		background: $bg;
	}
	::-webkit-scrollbar-thumb{
		background: $fg;
		&:hover{
			background: darken($fg, 10);
		}
	}
}



/*--------------------------------------------------------------
	Full
--------------------------------------------------------------*/
@mixin full($margin: 0, $not: 0){
	@if($not != top){
		top: 0;
	}
	@if($not != left){
		left: 0;
	}
	@if($not != right){
		right: 0;
	}
	@if($not != bottom){
		bottom: 0;
	}
	@if($margin == 1){
		margin: auto;
	}
}
@mixin abs($margin: 0, $not: 0){
	position: absolute;
	@include full($margin, $not);
}
@mixin fix($margin: 0, $not: 0){
	position: fixed;
	@include full($margin, $not);
}



/*--------------------------------------------------------------
	Pseudo
--------------------------------------------------------------*/
@mixin pseudo{
	content: '';
	display: block;
}
@mixin trait{
	&:after{
		@include pseudo;
		display: block;
		margin: 15px auto 30px;
		width: 20px;
		border: 1px solid currentColor;
		@include laptop {
			margin: 15px auto;
			width: 15px;
		}
		@include tablet {
			margin: 10px auto;
			width: 10px;
		}
	}
}



/*--------------------------------------------------------------
	Typo
--------------------------------------------------------------*/
@mixin title{
	font-size: 4em;
	letter-spacing: .1em;
	text-transform: uppercase;
	@include sans;
	@include laptopL{
		font-size: 3.7vw;
	}
	@include laptop{
		font-size: 3em;
	}
	@include mobileL{
		font-size: 2.15em;
	}
}
@mixin subtitle{
	font-size: 2.3em;
	@include dalafloda;
	@include laptop{
		font-size: 2em;
	}
	@include mobileL{
		font-size: 1.4em;
	}
}



/*--------------------------------------------------------------
	Cover
--------------------------------------------------------------*/
@mixin cover ($width: 16, $height: 9) {
	position: relative;
	overflow: hidden;
	img{
		position: absolute;
		top: 50%;
		left: 50%;
		min-width: 100%;
		min-height: 100%;
		transform: translate(-50%, -50%);
		@supports (object-fit: cover) {
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transform: none;
		}
		@include tablet{
			position: relative;
			top: 0;
			left: 0;
			transform: none;
		}
	}
}
