/*--------------------------------------------------------------
BASE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Mixins
- Reset
- Colors
- Sizes
- Fonts
- Scrollbar
- Links
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Mixins
--------------------------------------------------------------*/
@import "mixins";



/*--------------------------------------------------------------
	Reset
--------------------------------------------------------------*/
@import "reset";



/*--------------------------------------------------------------
	Colors
--------------------------------------------------------------*/
$vert1: #1c3c34;
$vert2: #a9c4ab;
$vert3: #00932e;
$vert4: #dfe3df;

$gris1: #acaeaf;
$gris2: #ccc;
$gris3: #eeefef;

$orange: #ff5517;
$jaune: #ffb714;
$bleu: #0080d7;

::selection{
	color: #fff;
	background: $vert1;
}



/*--------------------------------------------------------------
	Sizes
--------------------------------------------------------------*/
$layoutW: 1024px;
$gap: 20px;
$radius: 20px;
$headerH: 150px;
$footerH: 100px;



/*--------------------------------------------------------------
	Fonts
--------------------------------------------------------------*/
@include fontFace(droidserif, regular);
@include fontFace(droidserif, regular, true);
@include fontFace(droidserif, bold);
@include fontFace(droidserif, bold, true);
@include fontFace(dalafloda, bold);
@include fontFace(opensans, semibold);
@include fontFace(opensans, bold);
@include fontFace(opensans, light, true);

@mixin sans {
	font-family: opensans, arial;
	font-weight: 600;
}
@mixin dalafloda {
	font-family: dalafloda, georgia, serif;
}
body{
	font-family: droidserif;
	font-size: 14px;
	color: $vert1;
}
p{
	padding: 0 30px;
	line-height: 1.6;
	@include mobileL{
		padding: 0 15px;
	}
}
h1, h2, h3, h4{
	padding: 0 $gap;
	line-height: 1.2;
}



/*--------------------------------------------------------------
	Scrollbar
--------------------------------------------------------------*/
@include scrollbar(10px, $gris1, #fff);



/*--------------------------------------------------------------
	Links
--------------------------------------------------------------*/
a{
	transition: .2s;
}
a:hover{
	color: $vert3;
}
