/*--------------------------------------------------------------
SAVOIR-FAIRE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Job & Delivery
- Sourcing
- Commitment
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#savoir{
	h1{
		@include title;
	}
}



/*--------------------------------------------------------------
-	Job & Delivery
--------------------------------------------------------------*/
#job-delivery{
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: min-content minmax(150px, auto);
	grid-template-areas:
		"job del"
		"job img";
	@include laptop{
		grid-template-rows: min-content;
		grid-template-areas:
			"job job"
			"img del";
	}
	@include tablet{
		grid-template-columns: 1fr;
		grid-template-areas:
			"job"
			"del"
			"img";
	}
	&:before, &:after{
		content: '';
		transition: .8s $quint;
		transform-origin: 0 0;
	}
	&:before{
		grid-row: 1/3;
		grid-column: 1;
		background: $vert1;
		@include laptop{
			grid-column: 1/3;
			grid-row: 1;
		}
	}
	&:after{
		grid-row: 1;
		grid-column: 2;
		background: $gris3;
		transition-delay: .2s;
		@include laptop{
			grid-row: 2;
			grid-column: 2;
		}
		@include tablet{
			grid-column: 1;
			grid-row: 2;
		}
	}
	h1{
		@include trait;
	}

	// Job & delivery
	div{
		align-self: center;
		padding: $gap*2 0;
		z-index: 1;
		transition: .4s .6s;
	}

	// Job
	.job{
		grid-area: job;
		color: #fff;
		@include mobileL{
			padding: $gap*3 0;
		}
	}

	// Delivery
	.delivery{
		grid-area: del;
		transition-delay: .8s;
	}

	// Carte
	figure{
		position: relative;
		grid-area: img;
		z-index: 2;
		@include tablet{
			height: 350px;
		}
		&:before{
			@include pseudo;
			@include abs;
			background: #fff;
			z-index: -1;
			transition: .6s .4s $quint;
			transform-origin: 0 0;
		}
	}
	svg, img{
		@include abs(1);
		max-width: 90%;
		max-height: 90%;
		transition: 1.5s .4s;
	}
	.lines{
		stroke-dasharray: 350px;
		@for $i from 0 through 17{
			path:nth-child(#{$i}){
				transition: 3s ($i/10)+1s;
			}
		}
	}
	.triangles{
		@for $i from 1 through 17{
			path:nth-child(#{$i}){
				transition: .3s ($i/10)+2.5s;
			}
		}
	}

	// Off
	&.off{
		&:before, &:after{
			transform: scaleX(0);
			transition: 0s;
		}
		.job, .delivery{
			opacity: 0;
			transform: translateY(40px);
			transition: 0s;
		}
		figure{
			&:before{
				transform: scaleX(0);
				transition: 0s;
			}
		}
		svg, img{
			opacity: 0;
			transform: scale(.8);
			transition: 0s;
		}
		path{
			fill-opacity: 0;
			stroke-dashoffset: 350px;
			transition: 0s !important;
		}
	}
}



/*--------------------------------------------------------------
-	Sourcing
--------------------------------------------------------------*/
#sourcing{
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas: "txt img";
	@include laptop{
		grid-template-columns: auto;
		grid-template-areas:
			"img"
			"txt";
	}

	// Bgs
	&:before, &:after{
		content: '';
		grid-row: 1;
		transition: 1s $quint;
	}
	&:before{
		grid-column: 1/3;
		background: #fff;
		transform-origin: 0 0;
		@include laptop{
			grid-column: 1/2;
		}
	}
	&:after{
		grid-column: 1;
		background: url(/img/savoir-faire/pattern-fruits-legumes.png) center / 249px 185px;
		transition-delay: .4s;
		@include laptop{
			grid-row: 2;
			width: 120%;
		}
	}

	// Txt
	h2{
		@include subtitle;
		@include trait;
		font-size: 2.2em;
	}
	div{
		grid-area: txt;
		position: relative;
		max-width: 470px;
		margin: $gap;
		padding: $gap*2 0;
		align-self: center;
		justify-self: center;
		color: #fff;
		box-sizing: border-box;
		z-index: 1;
		@include laptopL{
			max-width: none;
		}
		@include laptop{
			margin: $gap*2;
			max-width: none;
		}
		@include mobileL{
			margin: $gap/2;
		}
		&:before{
			@include pseudo;
			@include abs;
			background: $vert1;
			transition: 1s .2s $quint;
			transform-origin: 0 0;
			z-index: -1;
		}
		h1{
			transition: .4s .8s;
		}
		h2{
			transition: .4s .9s;
		}
		p{
			transition: .4s 1s;
		}
		img{
			position: relative;
			top: 40px;
			margin-bottom: -75px;
			width: 150px;
			height: 150px;
			border-radius: 50%;
			background: #fff;
			transition: .4s .8s;
			@include laptopL{
				top: 0;
				margin: $gap 0 $gap*-1;
				width: 100px;
				height: 100px;
			}
		}
	}

	// Img
	figure{
		grid-area: img;
		@include cover(1440, 1080);
		z-index: 1;
		img{
			transition: 1s .4s $quint;
		}
		@include laptop{
			height: 350px;
			width: 120%;
		}
	}

	// Off
	&.off{
		&:before{
			transform: scaleX(0);
			transition: 0s;
		}
		&:after{
			opacity: 0;
			transform: scale(1.2);
			transition: 0s;
		}
		div{
			&:before{
				transform: scaleX(0);
				transition: 0s;
			}
			img{
				opacity: 0;
				transform: scale(.5);
				transition: 0s;
			}
		}
		h1, h2, p{
			opacity: 0;
			transform: translateY(40px);
			transition: 0s;
		}
		figure img{
			transform: translateX(-100%);
			transition: 0s;
		}
	}
}



/*--------------------------------------------------------------
-	Commitment
--------------------------------------------------------------*/
#commitment{
	position: relative;
	display: grid;
	padding: $gap*2 0;
	color: #fff;
	@include tablet{
		color: $vert1;
		background: $vert2;
	}

	// Bg
	&:before{
		@include pseudo;
		@include abs;
		background: $vert1;
		transition: 1s $quint;
		transform-origin: 0 0;
		@include tablet{
			background: $vert2;
		}
	}

	// Txt
	div{
		align-self: center;
		z-index: 1;
		transition: .4s .6s;
		@include tablet{
			z-index: 2;
		}
	}
	h1{
		@include trait;
	}
	.main & p{
		max-width: 800px;
	}

	// Logos
	figure{
		display: inline-block;
		margin: 60px $gap 0;
		height: 130px;
		@include tablet{
			margin: 50px $gap/2;
			height: 50px;
		}
	}
	img{
		max-height: 100%;
		transition: .4s .8s;
	}
	figure:last-child img{
		transition-delay: .9s;
	}

	// Off
	&.off{
		&:before{
			transform: scaleX(0);
			transition: 0s;
		}
		div, img{
			opacity: 0;
			transform: translateY(40px);
			transition: 0s;
		}
	}
}
