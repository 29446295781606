/*--------------------------------------------------------------
PRODUCTEURS > FICHE  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Img
- Présentation
- Produits
--------------------------------------------------------------*/



/*--------------------------------------------------------------
-	Global
--------------------------------------------------------------*/
#fiche .main{
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-areas:
		"i t"
		"d d";
	background: $gris3;
	@include tablet{
		grid-template-columns: 1fr;
		grid-template-areas:
			"i"
			"t"
			"d";
	}
}



/*--------------------------------------------------------------
-	Img
--------------------------------------------------------------*/
#fiche_img{
	grid-area: i;
	img{
		display: block;
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}



/*--------------------------------------------------------------
-	Présentation
--------------------------------------------------------------*/
#fiche_pres{
	display: flex;
	position: relative;
	grid-area: t;
	flex-direction: column;
	justify-content: space-between;
	padding: 80px 20%;
	color: $vert1;
	@include tablet{
		padding: 50px $gap;
	}

	// Back button
	.back{
		display: inline-block;
		margin-bottom: auto;
		padding: 10px 10px 10px 0;
		color: grey;
		@include sans;
		font-size: 13px;
		&::before{
			content: '< ';
		}
	}

	// Logo comptoir des producteurs
	.comptoir{
		display: block;
		position: absolute;
		top: 80px;
		right: 80px;
		width: 150px;
		height: 150px;
		opacity: .2;
		transform: rotate(-10deg);
	}

	// Title
	h1{
		padding: 0;
		@include subtitle;
	}

	// Name, Adresse, Main Products
	.address, .name, .mainProducts{
		margin-top: 10px;
		padding: 0;
		@include sans;
		font-weight: bold;
		img{
			vertical-align: middle;
		}
	}

	// Description
	.desc{
		margin-top: 15px;
		p{
			max-width: 500px;
			margin: 15px 0;
			padding: 0;
		}
	}

	// Respect des saisons
	.respect{
		margin: 80px 0 auto;
		padding: 0;
		@include sans;
		font-size: 13px;
		font-weight: bold;
		strong{
			text-transform: uppercase;
			text-decoration: underline;
		}
	}

	// Btn producteurs
	.btn_prod{
		margin-top: 30px;
		width: 120px;
		height: 120px;
		&::before{
			background: rgba($vert2, .2);
		}
	}
}



/*--------------------------------------------------------------
-	Produits
--------------------------------------------------------------*/
#dispo_produits{
	grid-area: d;
	padding: 120px $gap;
	text-align: center;
	background: $vert2;
	h2{
		margin-bottom: 50px;
		@include subtitle;
	}
	.select{
		display: inline-block;
		position: relative;
		vertical-align: top;
		width: 320px;
		margin: 0 40px;
		color: $vert1;
		font-size: 1.2em;
		font-style: italic;
		font-family: droidserif;
		text-align: center;
		border: none;
		border-radius: 20px;
		background: #fff;
		overflow: hidden;
		outline: none;
		&::before, &::after{
			content: '';
			position: absolute;
			top: 12px;
			right: 22px;
			border: 6px solid transparent;
			border-bottom: 12px solid $vert1;
		}
		&::after{
			top: 35px;
			transform: rotate(180deg);
		}
		ul{
			display: none;
			padding-bottom: 20px;
			line-height: 1.7;
		}
		li{
			cursor: pointer;
			transition: background .2s;
			&:hover{
				background: $vert4;
			}
		}
	}
	input{
		position: relative;
		width: 100%;
		height: 65px;
		color: inherit;
		font-size: inherit;
		font-style: inherit;
		text-align: inherit;
		border: none;
		background: none;
		z-index: 1;
		cursor: pointer;
		&::placeholder{
			color: inherit;
			font-size: 1.5em;
			font-style: normal;
			text-transform: uppercase;
		}
	}
	.months{
		input{
			font-size: 1.5em;
			font-style: normal;
			text-transform: uppercase;
			&::placeholder{
				font-size: 1em;
			}
		}
	}
	.open{
		ul{
			display: block;
		}
	}
	.list{
		margin-top: 50px;
		@include sans;
		font-size: 1.5em;
		letter-spacing: .1em;
		line-height: 1.5;
		span{
			font-size: .9em;
			font-weight: 300px;
			font-style: italic;
		}
	}
}
