/*--------------------------------------------------------------
BURGER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
#burger{
	display: none;
	position: relative;
	align-self: center;
	grid-area: l;
	width: 17px;
	height: 14px;
	border: 15px solid transparent;
	box-sizing: content-box;
	margin: -15px;
	justify-self: end;
	@include laptop{
		display: block;
	}
	span{
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 2px;
		background: currentColor;
		transform-origin: 0 100%;
		transition: .2s;
	}
	.top{
		top: 0;
	}
	.center{
		top: 0;
		bottom: 0;
		margin: auto;
	}
	.bottom{
		bottom: 0;
		transform-origin: 0 0;
	}
	.open &{
		.top{
			transform: rotate(45deg);
		}
		.center{
			transform: scaleX(0);
		}
		.bottom{
			transform: rotate(-45deg);
		}
	}
}
