/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
footer {
	@include abs(0, top);
	height: $footerH;
	line-height: $footerH;
	@include sans;
	font-size: 12px;
	background: #fff;
	#saisons &{
		background: $vert4;
	}
	#producteurs &{
		background: none;
	}
	@include tablet{
		height: $footerH/1.5;
		line-height: $footerH/1.5;
		font-size: 10px;
	}
	@include mobileL{
		padding: 10px 0;
		height: $footerH/2;
		line-height: 2;
		font-size: 10px;
	}
	.adresse {
		position: absolute;
		display: block;
		margin: auto;
		text-align: left;
		font-weight: normal;
		@include mobileL{
			position: relative;
			text-align: center;
			padding: 0 $gap + $pageBorder;
		}
	}
	p{
		line-height: inherit;
		margin: auto;
		text-align: right;
		padding: 0 $gap*2;
		max-width: $layoutW;
		@include mobileL{
			text-align: center;
			padding: 0 $gap + $pageBorder;
		}
	}
	a{
		font-weight: bold;
	}
	.legale:hover{
		font-weight: bold;
		color: $vert3;
		cursor: pointer;
	}
	
}
