/*--------------------------------------------------------------
HOME  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Video
- Logo
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Video
--------------------------------------------------------------*/
#home video{
	position: absolute;
	background: #000;
	@media (min-aspect-ratio: 16/9){
		width: 100%;
		height: 300%;
		top: -100%;
		left: 0;
	}
	@media (max-aspect-ratio: 16/9){
		width: 300%;
		height: 100%;
		top: 0;
		left: -100%;
	}
	@supports (object-fit: cover) {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		object-fit: cover;
	}
}



/*--------------------------------------------------------------
	Logo
--------------------------------------------------------------*/
#home img{
	@include abs(1);
	max-width: 500px;
	width: 80%;
}



.app-btn {
    position: absolute;
    bottom: 50px;
    right: 50px;
    z-index: 50;
    color: white;
    text-transform: uppercase;

    @include mobileL {
        font-size: 12px;
        bottom: 20px;
        right: 20px;
    }

    &:after {
        content: ""; 
        display: block; 
        margin: 0 auto;
        width: 40%; 
        padding-top: 7px;
        border-bottom: 1px solid white;
    }
}

#home{
	.adress{
		position: absolute;
		bottom: 50px;
		left: 50px;
		z-index: 50;
		color: white;
		text-transform: uppercase;
		font-weight: 700;

		ul{
			display: flex;
			flex-direction: column;
			li{
				display: inline-flex;
				margin-bottom: 1rem;
				img{
					position: static;
					width: 100px;
					height: 50px;
					margin: 0;
					display: block;
				}
				svg{
					fill: currentColor;
					position: static;
					width: 100px;
					height: 50px;
					margin: 0;
				}
				p{
	
				}
			}
		}
		@include tablet{
			bottom: 70px;
			scale: 0.7;
			width: 100%;
			display: flex;
			justify-content: center;
			left: 0;
			ul{
				display: flex;
				flex-direction: column;
				li{
					display: -webkit-box;
					margin-bottom: 2rem;
				}
			}
		}
		@include mobileM{
			scale: 0.6;
			bottom: 30px;
		}
	}
}
