/*--------------------------------------------------------------
HEADER  >>> TABLE OF CONTENTS:
----------------------------------------------------------------
- Global
- Logo
- Coords
- Menu
- Langs
- Newsletter
--------------------------------------------------------------*/



/*--------------------------------------------------------------
	Global
--------------------------------------------------------------*/
header{
	display: grid;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: $headerH;
	padding: $gap + $pageBorder $gap*2 0;
	grid-template-columns: auto 120px minmax(0, ($layoutW - 120 - 70)) 70px auto;
	grid-template-areas:
		". l c c ."
		". l m lang . "
		". l n . .";
	align-items: center;
	text-align: right;
	color: $vert1;
	font-size: .84em;
	@include sans;
	box-sizing: border-box;
	background: #fff;
	z-index: 10;
	#home &{
		color: #fff;
		background: none;
	}
	#producteurs &{
		color: $vert1;
		background: none;
	}
	&:before{
		@include pseudo;
		@include fix;
		background: $vert2;
		z-index: -1;
		opacity: 0;
		visibility: hidden;
		transition: .2s;
	}
	@include laptop{
		height: $headerH/1.3;
		padding-bottom: $gap + $pageBorder/2;
		grid-template-columns: 1fr;
		grid-template-rows: min-content auto auto auto;
		grid-template-areas:
			"l"
			"lang"
			"m"
			"c";
		font-size: 1em;
		text-align: center;
		&.open{
			height: 100vh;
			color: $vert1 !important;
			&:before{
				opacity: 1;
				visibility: visible;
			}
		}
		a:hover{
			color: inherit;
		}
	}
	@include mobileL{
		height: $headerH/2;
		padding: $pageBorder*2 $gap 0;
	}
	button{
		transition: .2s;
		&:hover{
			color: $vert3;
		}
	}
}



/*--------------------------------------------------------------
	Logo
--------------------------------------------------------------*/
#logo{
	grid-area: l;
	align-self: center;
	justify-self: start;
	fill: currentColor;
	&:hover{
		color: $vert2;
		#producteurs &{
			color: $vert3;
		}
	}
	svg{
		@include mobileL{
			width: 75px;
			height: 50px;
		}
	}
}




/*--------------------------------------------------------------
	Coords
--------------------------------------------------------------*/
#coords{
	grid-area: c;
	padding: 0;
	@include laptop{
		display: none;
		.open &{
			display: block;
		}
	}
	.catalog{
		text-decoration: underline;
	}
}



/*--------------------------------------------------------------
	Menu
--------------------------------------------------------------*/
#menu{
	grid-area: m;
	margin: $gap 0;
	@include laptop{
		display: none;
		grid-area: m;
		color: #fff;
		font-size: 1.7em;
		.open &{
			display: block;
		}
	}
	li{
		display: inline-block;
		margin: 0 15px;
		text-transform: uppercase;
		&:last-child{
			margin-right: 0;
		}
		@include laptop{
			display: block;
			margin: 0;
			padding: 6px 0;
		}
	}
	svg{
		fill: currentColor;
		@include laptop{
			margin-top: 20px;
			height: 17px;
			width: 17px;
			fill: $vert1;
		}
	}
	span{
		color: $vert3;
	}
}



/*--------------------------------------------------------------
	Langs
--------------------------------------------------------------*/
.lang{
	grid-area: lang;
	align-self: center;
	@include laptop{
		display: none;
		.open &{
			display: block;
		}
	}
	li{
		display: inline-block;
		margin: 0 10px;
		&:last-child{
			margin-right: 0;
		}
		@include laptop{
			margin: 0 7px;
		}
	}
	span{
		text-decoration: underline;
	}
}



/*--------------------------------------------------------------
	Newsletter
--------------------------------------------------------------*/
#newsletter{
	grid-area: n;
	position: absolute;
	right: 0;
	padding: 0 10px;
	color: $vert1;
	background: #fff;
	opacity: 0;
	transform: translateY(10px);
	visibility: hidden;
	transition: .2s;
	&.on{
		opacity: 1;
		visibility: visible;
		transform: none;
	}
	input{
		padding: 6px 3px;
		color: $vert1;
		border: none;
		background: none;
		&::placeholder{
			color: inherit;
			opacity: .7;
		}
	}
	span{
		@include abs;
		padding-right: 15px;
		line-height: 28px;
		text-align: center;
		background: #fff;
		transition: .2s;
		opacity: 0;
		visibility: hidden;
		cursor: pointer;
		&.on{
			opacity: 1;
			visibility: visible;
		}
		&:before, &:after{
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			right: 12px;
			margin: auto;
			width: 2px;
			height: 15px;
			background: currentColor;
		}
		&:before{
			transform: rotate(45deg);
		}
		&:after{
			transform: rotate(-45deg);
		}
	}
	.success{
		color: $vert3;
		&:before{
			right: 14px;
			height: 12px;
		}
		&:after{
			top: 2px;
			right: 20px;
			height: 7px;
		}
	}
	.error{
		color: #d26053;
	}
}
